<template>
  <div class="page Outbound">
    <div class="search">
      <a-select
        style="width: 200px; margin-right: 20px"
        v-model:value="params.speech_id"
        placeholder="请选择"
      >
        <a-select-option
          v-for="item in speech_list"
          :value="item.talk_temp_id"
          :key="item.talk_temp_id"
          >{{ item.talk_temp_name }}</a-select-option
        >
      </a-select>
      <a-range-picker
        style="margin-right: 20px"
        :locale="locale"
        v-model:value="params.date"
        valueFormat="YYYY-MM-DD"
        @change="hangleC"
        :disabledDate="disabledDay"
      />
      <a-radio-group style="margin-right: 20px">
        <a-radio-button value="a">昨日</a-radio-button>
        <a-radio-button value="b">今日</a-radio-button>
        <a-radio-button value="c">本周</a-radio-button>
        <a-radio-button value="d">自定义</a-radio-button>
      </a-radio-group>
      <a-select
        mode="multiple"
        placeholder="Please select"
        style="width: 200px; margin-right: 20px"
        v-model:value="params.user_id"
      >
        <a-select-option
          v-for="item in userList"
          :value="item.user_id"
          :key="item.user_id"
          >{{ item.username }}</a-select-option
        >
      </a-select>
    </div>
    <a-tabs v-model:activeKey="activeKey">
      <a-tab-pane key="1" tab="意向标签">
        <div class="echart-box">
          <echart id="Echart" :option="option"></echart>
        </div>
      </a-tab-pane>
      <a-tab-pane key="2" tab="通话数量"
        ><div class="echart-box">
          <echart id="Echart2" :option="option2"></echart></div
      ></a-tab-pane>
      <a-tab-pane key="3" tab="接通率"
        ><div class="echart-box">
          <echart id="Echart3" :option="option3"></echart></div
      ></a-tab-pane>
      <a-tab-pane key="5" tab="通话时长"
        ><div class="echart-box">
          <echart id="Echart4" :option="option4"></echart></div
      ></a-tab-pane>
    </a-tabs>

    <div style="margin-bottom: 25px">
      <div style="margin-bottom: 8px; text-align: right; width: 100%">
        <a-button type="primary" @click="handleOut">导出</a-button>
      </div>
      <a-table
        :dataSource="all_list"
        :columns="columns"
        :pagination="{ pageSize: 30 }"
        :scroll="{ x: 1800 }"
        rowKey="out_day"
      >
        <template #name="{ record }">
          {{
            record.total_number === 0
              ? 0
              : ((record.capacity_number * 100) / record.total_number).toFixed(
                  2
                )
          }}%
        </template>
        <template #age="{ record }">
          {{
            record.total_number === 0
              ? 0
              : record.capacity_number / record.capacity_number
          }}
        </template>
        <!-- <template #hobby="{ record }">
          {{ record.capacity_number / record.total_number }}
        </template> -->
      </a-table>
    </div>
    <!-- <div>
      <div class="top">
        <h3>坐席数据</h3>
        <a-button type="primary">导出</a-button>
      </div>
      <a-table :dataSource="data2" :columns="columns" />
    </div> -->
  </div>
</template>

<script>
import { reactive, toRefs } from "@vue/reactivity";
import Echart from "../../components/Echarts2.vue";
import { onMounted } from "vue";
import { getList, getUserList, getSpeechList } from "../../service/outbound";
import locale from "ant-design-vue/es/date-picker/locale/zh_CN";
import { formDate } from "../../utils/function";
import moment from "moment";
import CsvExportor from "csv-exportor";
const data = [
  {
    key: "1",
    name: "2021-12-23",
    age: 0,
    address: "0",
    name2: "00秒",
    age2: 0,
    address2: "00秒",
    name3: "0",
    age3: 0,
    address3: "0",
    name4: "0",
    age4: 0,
    address4: "0",
    name5: "0",
    age5: 0,
    address5: "0",
  },
  {
    key: "2",
    name: "2021-12-23",
    age: 0,
    address: "0",
    name2: "00秒",
    age2: 0,
    address2: "00秒",
    name3: "0",
    age3: 0,
    address3: "0",
    name4: "0",
    age4: 0,
    address4: "0",
    name5: "0",
    age5: 0,
    address5: "0",
  },
  {
    key: "3",
    name: "2021-12-23",
    age: 0,
    address: "0",
    name2: "00秒",
    age2: 0,
    address2: "00秒",
    name3: "0",
    age3: 0,
    address3: "0",
    name4: "0",
    age4: 0,
    address4: "0",
    name5: "0",
    age5: 0,
    address5: "0",
  },
  {
    key: "4",
    name: "2021-12-23",
    age: 0,
    address: "0",
    name2: "00秒",
    age2: 0,
    address2: "00秒",
    name3: "0",
    age3: 0,
    address3: "0",
    name4: "0",
    age4: 0,
    address4: "0",
    name5: "0",
    age5: 0,
    address5: "0",
  },
  {
    key: "5",
    name: "2021-12-23",
    age: 0,
    address: "0",
    name2: "00秒",
    age2: 0,
    address2: "00秒",
    name3: "0",
    age3: 0,
    address3: "0",
    name4: "0",
    age4: 0,
    address4: "0",
    name5: "0",
    age5: 0,
    address5: "0",
  },
  {
    key: "6",
    name: "2021-12-23",
    age: 0,
    address: "0",
    name2: "00秒",
    age2: 0,
    address2: "00秒",
    name3: "0",
    age3: 0,
    address3: "0",
    name4: "0",
    age4: 0,
    address4: "0",
    name5: "0",
    age5: 0,
    address5: "0",
  },
  {
    key: "7",
    name: "2021-12-23",
    age: 0,
    address: "0",
    name2: "00秒",
    age2: 0,
    address2: "00秒",
    name3: "0",
    age3: 0,
    address3: "0",
    name4: "0",
    age4: 0,
    address4: "0",
    name5: "0",
    age5: 0,
    address5: "0",
  },
  {
    key: "8",
    name: "2021-12-23",
    age: 0,
    address: "0",
    name2: "00秒",
    age2: 0,
    address2: "00秒",
    name3: "0",
    age3: 0,
    address3: "0",
    name4: "0",
    age4: 0,
    address4: "0",
    name5: "0",
    age5: 0,
    address5: "0",
  },
  {
    key: "9",
    name: "2021-12-23",
    age: 0,
    address: "0",
    name2: "00秒",
    age2: 0,
    address2: "00秒",
    name3: "0",
    age3: 0,
    address3: "0",
    name4: "0",
    age4: 0,
    address4: "0",
    name5: "0",
    age5: 0,
    address5: "0",
  },
];
const columns = [
  {
    title: "日期",
    dataIndex: "out_day",
  },
  {
    title: "外呼总量(个)",
    dataIndex: "total_number",
  },
  {
    title: "接通量(个)",
    dataIndex: "capacity_number",
  },
  {
    title: "接通率(%)",
    dataIndex: "name",
    slots: {
      customRender: "name",
    },
  },
  {
    title: "外呼通话总时长",
    dataIndex: "total_duration",
  },
  {
    title: "请求人机切换数量(个)",
    dataIndex: "man_machine_number",
  },
  {
    title: "外呼通话平均时长",
    dataIndex: "age",
    slots: {
      customRender: "age",
    },
  },
  {
    title: "监听数",
    dataIndex: "listeners_number",
  },
  {
    title: "成功人机切换数量",
    dataIndex: "man_machine_success_number",
  },
  {
    title: "人工通话总时长",
    dataIndex: "man_total_duration",
  },
  // {
  //   title: "人工通话平均时长",
  //   dataIndex: "hobby",
  //   slots: {
  //     customRender: "hobby",
  //   },
  // },
  {
    title: "A级(有明确意向)",
    dataIndex: "one_number",
  },
  {
    title: "B级(可能有意向)",
    dataIndex: "two_number",
  },
  {
    title: "C级(明确拒绝)",
    dataIndex: "three_number",
  },
  {
    title: "D级(用户忙)",
    dataIndex: "four_number",
  },
  {
    title: "E级(拨打失败)",
    dataIndex: "fif_number",
  },
  {
    title: "F级(无效客户)",
    dataIndex: "six_number",
  },
];
export default {
  name: "Outbound",
  components: { Echart },
  setup() {
    const state = reactive({
      activeKey: "1",
      value: undefined,
      option: {
        //   text: 'Stacked Line'
        // },
        tooltip: {
          trigger: "axis",
        },
        legend: {
          data: [
            "A级(明确有意向)",
            "B级(可能有意向)",
            "C级(明确拒绝)",
            "D级(用户忙)",
            "E级(拨打失败)",
            "F级(无效客户)",
          ],
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },
        toolbox: {
          feature: {
            saveAsImage: {
              show: false,
            },
          },
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: [
            "21",
            "22",
            "23",
            "24",
            "25",
            "26",
            "27",
            "28",
            "29",
            "30",
            "31",
            "32",
            "33",
            "34",
          ],
        },
        yAxis: {
          type: "value",
        },
        series: [
          {
            name: "A级(明确有意向)",
            type: "line",
            stack: "Total",
            data: [1, 2, 2, 4, 2, 6, 2, 2, 2, 3, 1, 2, 3, 4],
          },
          {
            name: "B级(可能有意向)",
            type: "line",
            stack: "Total",
            data: [2, 2, 4, 2, 6, 2, 1, 2, 2, 3, 1, 2, 3, 4],
          },
          {
            name: "C级(明确拒绝)",
            type: "line",
            stack: "Total",
            data: [0, 2, 6, 2, 2, 2, 3, 1, 2, 3, 4, 1, 2, 2],
          },
          {
            name: "D级(用户忙)",
            type: "line",
            stack: "Total",
            data: [6, 2, 2, 2, 3, 1, 2, 3, 4, 1, 2, 2, 4, 2],
          },
          {
            name: "E级(拨打失败)",
            type: "line",
            stack: "Total",
            data: [1, 2, 2, 2, 3, 1, 2, 3, 4, 2, 2, 4, 2, 6],
          },
          {
            name: "F级(无效客户)",
            type: "line",
            stack: "Total",
            data: [2, 2, 3, 1, 2, 1, 2, 2, 4, 2, 6, 2, 3, 4],
          },
        ],
      },
      option2: {
        //   text: 'Stacked Line'
        // },
        tooltip: {
          trigger: "axis",
        },
        legend: {
          data: ["外呼量", "接通量", "请求人机切换数量"],
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },
        toolbox: {
          feature: {
            saveAsImage: {
              show: false,
            },
          },
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: [
            "21",
            "22",
            "23",
            "24",
            "25",
            "26",
            "27",
            "28",
            "29",
            "30",
            "31",
            "32",
            "33",
            "34",
          ],
        },
        yAxis: {
          type: "value",
        },
        series: [
          {
            name: "外呼量",
            type: "line",
            stack: "Total",
            data: [1, 2, 2, 4, 2, 6, 2, 2, 2, 3, 1, 2, 3, 4],
          },
          {
            name: "接通量",
            type: "line",
            stack: "Total",
            data: [2, 2, 4, 2, 6, 2, 1, 2, 2, 3, 1, 2, 3, 4],
          },
          {
            name: "请求人机切换数量",
            type: "line",
            stack: "Total",
            data: [0, 2, 6, 2, 2, 2, 3, 1, 2, 3, 4, 1, 2, 2],
          },
        ],
      },
      option4: {
        //   text: 'Stacked Line'
        // },
        tooltip: {
          trigger: "axis",
        },
        legend: {
          data: ["外呼通话总时长"],
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },
        toolbox: {
          feature: {
            saveAsImage: {
              show: false,
            },
          },
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: [
            "21",
            "22",
            "23",
            "24",
            "25",
            "26",
            "27",
            "28",
            "29",
            "30",
            "31",
            "32",
            "33",
            "34",
          ],
        },
        yAxis: {
          type: "value",
        },
        series: [
          {
            name: "外呼通话总时长",
            type: "line",
            stack: "Total",
            data: [1, 2, 2, 4, 2, 6, 2, 2, 2, 3, 1, 2, 3, 4],
          },
        ],
      },
      option3: {
        //   text: 'Stacked Line'
        // },
        tooltip: {
          trigger: "axis",
        },
        legend: {
          data: ["接通率"],
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },
        toolbox: {
          feature: {
            saveAsImage: {
              show: false,
            },
          },
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: [
            "21",
            "22",
            "23",
            "24",
            "25",
            "26",
            "27",
            "28",
            "29",
            "30",
            "31",
            "32",
            "33",
            "34",
          ],
        },
        yAxis: {
          type: "value",
        },
        series: [
          {
            name: "接通率",
            type: "line",
            stack: "Total",
            data: [],
          },
        ],
      },
      all_list: [], //表格数据
      speech_list: [], //话术列表
      userList: [],
      // disabledStartDate: undefined,
    });
    const disabledDay = (current) => {
      // 只能选择当前日期的两个月范围内
      return (
        current &&
        (current > moment().endOf("day") ||
          current < moment().subtract(1, "months"))
      );
      // 只能选择当前日期的前后一个月范围
      // return current && (current >  moment().add(1, 'months') || current <  moment().subtract(1, 'months'));
    };
    const params = reactive({
      speech_id: undefined,
      date: [],
      user_id: [],
    });
    onMounted(async () => {
      // console.log("fdfk");
      // console.log(formDate(Date.now()));
      // console.log(formDate(Date.now() - 1000 * 60 * 60 * 24 * 30));
      params.date = [
        formDate(Date.now() - 1000 * 60 * 60 * 24 * 30),
        formDate(Date.now()),
      ];
      console.log(params.date);
      const res = await getUserList({
        access_token: sessionStorage.getItem("token"),
      });
      console.log(res);
      state.userList = res.data.data;
      const res2 = await getSpeechList({
        access_token: sessionStorage.getItem("token"),
      });
      console.log(res2);
      state.speech_list = res2.data.data;
      params.speech_id = res2.data.data[0].talk_temp_id;
      init();
    });
    const init = async () => {
      console.log(params.date);
      state.all_list = [];
      const res = await getList({
        access_token: sessionStorage.getItem("token"),
        speech_id: params.speech_id,
        date: JSON.stringify({ start: params.date[0], end: params.date[1] }),
        user_id: params.user_id.join(","),
      });
      console.log(res);
      const {
        time_range,
        label_list,
        all_list,
        A,
        B,
        C,
        D,
        E,
        F,
        // speech_list,
        all_call, //通话数量
        bill_call, //通话时长
      } = res.data.data;
      state.option.legend.data = label_list;
      state.option.xAxis.data = time_range;
      state.option2.xAxis.data = time_range; //通话数量
      state.option4.xAxis.data = time_range; //通话时长
      state.option3.xAxis.data = time_range; //通话时长
      state.option.series[0].name = label_list[0];
      state.option.series[0].data = A;
      state.option.series[1].name = label_list[1];
      state.option.series[1].data = B;
      state.option.series[2].name = label_list[2];
      state.option.series[2].data = C;
      state.option.series[3].name = label_list[3];
      state.option.series[3].data = D;
      state.option.series[4].name = label_list[4];
      state.option.series[4].data = E;
      state.option.series[5].name = label_list[5];
      state.option.series[5].data = F;
      // console.log(all_list);
      for (let key in all_list) {
        // console.log(key)
        // let data = ;
        // let time=data.
        all_list[key].time = new Date(key).getTime();
        state.all_list.unshift(all_list[key]);
      }
      state.all_list.sort((a, b) => b.time - a.time);
      // state.all_list = all_list;
      state.option2.series[0].data = all_call.capacity_number;
      state.option2.series[1].data = all_call.total_number;
      state.option2.series[2].data = all_call.man_machine_number;
      state.option4.series[0].data = bill_call;
      // all_call.total_number.forEach( (item,index) => {
      //   if (item === 0) {
      //     state.option3.series[0].data.push(0);
      //   } else {
      //     state.option3.series[0].data.push(
      //       all_call.capacity_number[index] / item
      //     );
      //   }
      // });
      all_call.total_number.forEach((element, index) => {
        // console.log(element, index);
        if (element === 0) {
          state.option3.series[0].data.push(0);
        } else {
          state.option3.series[0].data.push(
            all_call.capacity_number[index] / element
          );
        }
      });
      // state.option3.series[0].data
      // console.log(state.all_list);
      // state.speech_list = speech_list;
      // params.speech_id = speech_list[0].talk_temp_id;
    };
    const hangleC = (e) => {
      console.log(e);
    };
    const handleOut = () => {
      // console.log(state.all_list);
      let header = [];
      columns.forEach((item) => header.push(item.title));
      let dateList = state.all_list.map((item) => {
        return {
          out_day: item.out_day,
          total_number: item.total_number,
          capacity_number: item.capacity_number,
          name: (item.capacity_number * 100) / item.total_number || 0,
          total_duration: item.total_duration,
          man_machine_number: item.man_machine_number,
          age: item.capacity_number / item.capacity_number || 0,
          listeners_number: item.listeners_number,
          man_machine_success_number: item.man_machine_success_number,
          man_total_duration: item.man_total_duration,
          one_number: item.one_number,
          two_number: item.two_number,
          three_number: item.three_number,
          four_number: item.four_number,
          fif_number: item.fif_number,
          six_number: item.six_number,
        };
      });
      // console.log(dateList);
      CsvExportor.downloadCsv(dateList, { header }, "报表数据.csv");
    };
    return {
      ...toRefs(state),
      data,
      columns,
      params,
      locale,
      formDate,
      hangleC,
      disabledDay,
      handleOut,
    };
  },
};
</script>
<style>
.Outbound .ant-pagination {
  display: none;
}
</style>
<style scoped>
.Outbound {
  padding: 15px;
  overflow: auto;
}
.Outbound::-webkit-scrollbar {
  display: none; /* Chrome Safari */
}
.search {
  margin-bottom: 10px;
}
.echart-box {
  width: 100%;
  height: 400px;
}
.top {
  margin-bottom: 8px;
  width: 100%;
  display: flex;
  justify-content: space-between;
}
</style>