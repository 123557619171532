import http from "../axios/http"
//智能外呼报表
export let getList = (params) => {
    return http.get("/report_list/ai_out", { params })
}
//获取所有子集
export let getUserList = (params) => {
    return http.get("/report_list/user_list", { params })
}
//获取所有子集
export let getSpeechList = (params) => {
    return http.get("/report_list/speech_list", { params })
}
